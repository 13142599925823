import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { pruneUndefined } from 'src/app/shared/utils/prune-undefined';
import { environment } from '../../../environments/environment';
import { Collection, CollectionOptions } from '../models/collection';
import { Product } from '../models/product.model';
import { Resource } from '../models/resource';

export type ProductsCollectionOptions = {
  recommended?: 'true' | 'false';
  brand?: number;
  /**
   * A comma separated list of categories id's
   */
  category?: string | number;
  /**
   * A comma separated list of subcategories id's
   */
  subcategory?: string | number;
  name?: string;
  searchFeatured?: 'true' | 'false';
  likedByUser?: boolean;
  sku?: string | number;
  discountedOnly?: true;
  orderBy?: string;
  orderDirection?: 'asc' | 'desc';
} & CollectionOptions;

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  readonly #http = inject(HttpClient);

  readonly #cache = new Map<Product['id'], Product>();

  getCollection(options: ProductsCollectionOptions = {}) {
    const url = `${environment.apiUrl}/products`;
    const params = new HttpParams().appendAll({
      ...pruneUndefined(options),
      active: 'true',
    });
    return this.#http
      .get<Collection<Product>>(url, { params })
      .pipe(
        tap((res) =>
          res.data.forEach((product) => this.#cache.set(product.id, product)),
        ),
      );
  }

  getItem(id: Product['id']) {
    const url = `${environment.apiUrl}/products/${id}`;
    return this.#http.get<Resource<Product>>(url);
  }

  getItemFromCache(id: Product['id']) {
    return this.#cache.get(+id);
  }
}
